.quill {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

/*TOOLBAR*/

.quill .ql-toolbar {
	align-items: center;
	background-color: #f4f6f9;
	border: 0;
	border-bottom: 2px solid #ffffff;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	display: flex;
	flex: 0 0 50px;
	flex-direction: row;
	position: sticky;
	top: 0;
	z-index: 999;
}

.quill .ql-toolbar .ql-formats {
	margin-right: 30px;
}

/*EDITOR*/

.quill .ql-container {
	background-color: #f4f6f9;
	border: 0;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	display: flex;
	flex: 1;
	overflow: scroll;
}

.quill .ql-container .ql-editor {
	color: #454867;
	flex: 1;
	font-family: 'Circular', sans-serif;
	font-size: 1.4rem;
	font-weight: 400;
	height: auto;
	letter-spacing: -0.2px;
	line-height: 1.3;
}

.quill .ql-container .ql-editor h1,
.quill .ql-container .ql-editor h2,
.quill .ql-container .ql-editor h3,
.quill .ql-container .ql-editor h4 {
	margin-top: 20px;
	margin-bottom: 10px;
}
.quill .ql-container .ql-editor p {
	margin-bottom: 10px;
}
.quill .ql-container .ql-editor blockquote {
	margin-bottom: 20px;
}

.quill .ql-container .ql-editor:before {
	font-style: normal;
}

/*TOOLTIPS*/

.quill .ql-container .ql-tooltip {
	align-items: center;
	border: 0;
	display: flex;
	height: 45px;
	left: 10px !important;
	top: 0 !important;
	z-index: 999;
}
.quill .ql-container .ql-tooltip.ql-hidden {
	display: none !important;
}
